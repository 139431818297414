import React from "react"
import { graphql } from "gatsby"
import tw from "twin.macro"
import SEO from "../components/SEO"
import { ArticleCollection } from "../model/cms"
import { Layout } from "../components/Layout"
import { ArticleView } from "../components/ArticleView"

const Container = tw.div`px-4`
const PageTitle = tw.h1`font-display font-semibold text-6xl`
const ArticleContainer = tw.div`max-w-3xl mx-auto py-10`

export interface ArticlesPageProps {
  data: {
    articles: ArticleCollection
  }
}

export default function ArticlesPage({ data }: ArticlesPageProps) {
  const articles = data.articles.edges.map(edge => edge.node)

  return (
    <Layout>
      <SEO
        title="Articles | Long Rock Labs"
        description="Read blockchain articles from our experts."
        meta={[]}
      />
      <Container>
        <ArticleContainer>
          <PageTitle>Articles</PageTitle>
        </ArticleContainer>
        {articles.map(article => (
          <ArticleView key={article.slug} article={article} />
        ))}
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  {
    articles: allGraphCmsArticle(sort: { order: DESC, fields: updatedAt }) {
      edges {
        node {
          title
          slug
          excerpt
          createdAt
          category
          author {
            name
            title
            avatar {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 256)
                }
              }
            }
          }
        }
      }
    }
  }
`
